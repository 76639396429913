<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height class="px-0 py-0">
        <v-layout row wrap fill-height>
          <v-flex sm12 md7 lg8 xl8 class="hidden-sm-and-down primary">
            <v-layout row fill-height align-center justify-center>
              <v-flex xs11>
                <v-layout column>
                  <v-flex xs12>
                    <span class="text-center brand white--text">idenfit</span>
                  </v-flex>
                  <v-flex xs12>
                    <div style="font-size: 20px;" class="white--text text-md-center">
                      <span id="typed-area" class="mx-0 my-0"></span>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md5 lg4 xl4>
            <v-layout fill-height justify-center align-center>
              <v-flex xs10 sm8 md10>
                <transition name="slide-x-transition" mode="out-in">
                  <router-view />
                </transition>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <notification-bar />
  </v-app>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description auth-layout Component Logic
   * @date 24.07.2020
   */
  import Typed from "typed.js";
  export default {
    name: "auth-layout",
    mounted() {
      let vm = this;
      new Typed("#typed-area", {
        strings: [
          vm.$t("auth.showcase.welcome"),
          vm.$t("auth.showcase.cloud"),
          vm.$t("auth.showcase.identify"),
          vm.$t("auth.showcase.manage"),
          vm.$t("auth.showcase.freedom")
        ],
        typeSpeed: 40,
        loop: true
      });
    }
  };
</script>

<style scoped>
  .brand {
    font-family: "Paytone One", sans-serif;
    font-size: 100px;
    display: block;
  }
</style>
